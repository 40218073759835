<template>
  <div class="pl-4 pr-1 custom-container" style="width: 100%" >
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="histories"
          :loading="loading"
          @fetchHistories="fetchHistories($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './HistoryDatatable.vue'
import {
  getDeleteHistoriesUsingGET as getHistories
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      histories: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    fetchHistories(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.search && options.search.length > 1) {
        obj.resourceName = options.search
      }

      if (options && options.type) {
        obj.resourceType = options.type
      }

      if (options && options.dateFrom) {
        obj.dateFrom = options.dateFrom
      }

      if (options && options.dateTill) {
        obj.dateTill = options.dateTill
      }

      getHistories(obj)
        .then((res) => {
          this.histories = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>
